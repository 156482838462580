<template>
    <div class="container-fluid view view-item candidate" v-if="candidate">
        <div class="content-view borderless">
            <div class="header">
                <div class="header-image">
                    <span class="is-online"></span>
                    <img :src="candidate.profile?.image" :alt="`${candidate.profile?.first_name} ${candidate.profile?.last_name}`">
                </div>
                <div class="header-content header-grid">
                    
                    <router-link :to="{ name: 'ViewCandidate', params: { id: candidate.id } }" class="open-view">
                        <button class="btn btn-secondary icon-btn" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                                <g>
                                    <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                                </g>
                            </svg>
                        </button>
                    </router-link>

                    <div class="grid-col first-col">
                        <div class="title">
                            <h1>{{ candidate.profile?.first_name }} {{ candidate.profile?.last_name }}</h1>
                        </div>
                        <ul class="item-list">
                            <li v-if="candidate.about?.job_title">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.819" height="17.289" viewBox="0 0 14.819 17.289">
                                    <g>
                                        <g>
                                            <path d="M7.087 6.322a4.322 4.322 0 1 1 4.322 4.322 4.322 4.322 0 0 1-4.322-4.322zm6.175 5.557h-3.7A5.557 5.557 0 0 0 4 17.437v1.235a.617.617 0 0 0 .617.617H18.2a.617.617 0 0 0 .617-.617v-1.235a5.557 5.557 0 0 0-5.555-5.557z" transform="translate(-4 -2) translate(4 2) translate(-4 -2)"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>{{ candidate.about?.job_title }}</span>
                            </li>
                            <li v-if="candidate.profile?.country_name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                                    <g>
                                        <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                                    </g>
                                </svg>
                                <span>{{ candidate.profile?.country_name }}</span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                    <g>
                                        <g>
                                            <path d="M10.5 2a8.5 8.5 0 1 0 8.5 8.5A8.5 8.5 0 0 0 10.5 2zm2.55 11.05h-5.1a.85.85 0 0 1 0-1.7h5.1a.85.85 0 1 1 0 1.7zm0-3.4h-5.1a.85.85 0 1 1 0-1.7h5.1a.85.85 0 1 1 0 1.7z" transform="translate(-2 -2) translate(2 2) translate(-2 -2)"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>Salary</span>
                            </li>
                        </ul>
                    </div>
                    <div class="grid-col second-col">
                        <p class="col-title">{{ $t('New Application to Vacancy') }}</p>
                        <p>UI / UX Designer</p>
                    </div>
                    <div class="grid-col third-col">
                        <p class="col-title">{{ $t('Attached CV file') }}</p>
                        <p>
                            {{ $t('Download') }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.125" height="19.738" viewBox="0 0 19.125 19.738">
                                <g>
                                    <path d="M34 21.737a5.836 5.836 0 0 0-10.883 0 4.627 4.627 0 0 0 .506 9.226h1.547v-1.851h-1.543a2.776 2.776 0 1 1 0-5.553h.891l.179-.694a3.989 3.989 0 0 1 7.73 0l.179.694h.894a2.776 2.776 0 1 1 0 5.553h-1.544v1.851H33.5a4.627 4.627 0 0 0 .5-9.226z" transform="translate(-19 -18.011)"/>
                                    <path d="M39.227 49v7.021l-2.159-2.159-1.308 1.307 3.74 3.739a.925.925 0 0 0 1.308 0l3.739-3.739-1.308-1.308-2.159 2.159V49z" transform="translate(-19 -18.011) translate(-11.59 -21.43)"/>
                                </g>
                            </svg>
                        </p>
                    </div>
                    <div class="grid-col forth-col">
                        <p class="col-title">{{ $t('Contact') }}</p>
                        <p class="small">
                            {{ $t('Contact Recruiter') }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.712" height="18.299" viewBox="0 0 19.712 18.299">
                                <g>
                                    <g>
                                        <path d="M19.216 9.15H12.5a6.506 6.506 0 0 0-6.5 6.5v1.776a6.489 6.489 0 0 0 4.422 6.16l.26.087-.137.237c-.354.609-.833 1.566-1.465 2.923a.431.431 0 0 0 .085.486.441.441 0 0 0 .484.09l7.611-3.483h1.955a6.506 6.506 0 0 0 6.5-6.5v-1.773a6.506 6.506 0 0 0-6.499-6.503zm-8.437 8.764A1.224 1.224 0 1 1 12 16.689a1.226 1.226 0 0 1-1.222 1.225zm5.078 0a1.224 1.224 0 1 1 1.224-1.225 1.226 1.226 0 0 1-1.225 1.225zm5.078 0a1.224 1.224 0 1 1 1.224-1.225 1.226 1.226 0 0 1-1.226 1.225z" transform="translate(-6 -9.15) translate(6 9.15) translate(-6 -9.15)"/>
                                    </g>
                                </g>
                            </svg>
                        </p>
                    </div>

                    <div class="header-buttons">
                        <router-link v-if="canEdit" :to="{ name: 'EditCandidate' }">
                            <button class="btn btn-secondary" type="button">{{ $t('Edit') }}</button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="item-details-table">
                <div class="detail">
                    <p class="detail-title">{{ $t('Languages') }}</p>
                    <p>{{ langList ? langList : '-' }}</p>
                </div>
                <div class="detail">
                    <p class="detail-title">{{ $t('Driving Licences') }}</p>
                    <p>Driving Licences</p>
                </div>
                <div class="detail">
                    <p class="detail-title">{{ $t('Has Car') }}</p>
                    <p>Has Car</p>
                </div>
                <div class="detail">
                    <p class="detail-title">{{ $t('Can use private car for work') }}</p>
                    <p>Can use private car</p>
                </div>
            </div>
            <div class="item-content">
                
                <div class="tab-controls">
                    <span @click="currentComponent = 'about'" :class="[currentComponent == 'about' ? 'active' : '']">{{ $t('About') }}</span>
                    <span @click="currentComponent = 'career'" :class="[currentComponent == 'career' ? 'active' : '']">{{ $t('Experience') }}</span>
                </div>

                <transition name="tab-slide" mode="out-in">
                    <component :is="currentComponent" :candidate="candidate"></component>
                </transition>
                
                <slot name="bottomButtons">
                    <div class="item-footer-buttons d-flex align-items-center justify-content-between">
                        <div class="left-buttons">
                            <button class="btn btn-primary">{{ $t('Offer job') }}</button>
                            <button class="btn btn-secondary">{{ $t('Print') }}</button>
                        </div>
                        <div class="right-buttons">
                            <a class="text-btn">{{ $t('Report applicant') }}</a>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { candidateService } from "@/services/candidateService";
import About from "@/components/candidates/view/About";
import Career from "@/components/candidates/view/Career";

export default {
    name: "ViewCandidate",
    data() {
        return {
            candidate: null,
            moment: moment,
            canEdit: false,
            currentComponent: 'about'
        }
    },
    props: {
        _id: {
            default: null
        }
    },
    computed: {
        candidateId() {
            if (this._id != null) {
                return this._id;
            }
            return this.$route.params.id
        },
        langList() {
            if (this.candidate) {
                return this.candidate.languages.map(lang => lang.name).join(', ');
            }
            return '';
        }
    },
    created() {
        this.getCandidate()
    },
    methods: {
        getCandidate() {
            candidateService.getCandidate(this.candidateId)
                .then(response => {
                    this.candidate = response.data.data
                    this.canEdit = response.data.canEdit
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
        },
    },
    components: {
        'about': About,
        'career': Career
    }
}
</script>