import axios from '@/axios';

class CandidateService {
    getCandidates(uriParams) {
        const reducer = (accumulator, currentValue) =>
            accumulator += currentValue[1] !== undefined
                ? (Array.isArray(currentValue[1]) && currentValue[1].length === 0 ? '' : currentValue.join('=') + '&')
                : '';

        let url = '/candidates' + Object.entries(uriParams).reduce(reducer, '?').slice(0, -1)

        // return axios.get('/candidates', {
        //     params: uriParams
        // })
        return axios.get(url)
    }
    getCandidate(candidateId) {
        return axios.get(`/candidates/${candidateId}`)
    }
    updateProfileImage(candidateId, formData) {
        return axios.post(`/candidates/${candidateId}/image`, formData)
    }
    updateCandidate(candidateId, data) {
        return axios.post(`/candidates/${candidateId}`, data)
    }
    uploadCVFile(candidateId, formData) {
        return axios.post(`/candidates/${candidateId}/CV`, formData)
    }

    applications(status = null) {
        if (status != null) {
            return axios.get(`/candidate/applications/vacancies/${status}`)
        }
        return axios.get(`/candidate/applications/vacancies`);
    }

    application(application) {
        return axios.get(`/candidate/applications/${application}`);
    }
}

export const candidateService = new CandidateService();