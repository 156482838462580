<template>
    <div class="item-tab">
        <div class="item-content-section" v-if="candidate.experiences?.length">
            <h2>{{ $t("Experience") }}</h2>
            <div class="single-item" v-for="(experience, index) in candidate.experiences" :key="index">
                <p class="single-item-title">{{ experience.job_title }}</p>
                <p class="single-item-subtitle" v-if="experience.date_from || experience.date_to">
                    {{ (experience.date_from && experience.date_to ? `${experience.date_from} - ${experience.date_to}` : `${experience.date_from}${experience.date_to}`) }}
                </p>
                <p>{{ experience.description }}</p>
            </div>                    
        </div>

        <div class="item-content-section" v-if="candidate.courses?.length">
            <h2>{{ $t("Courses") }}</h2>
            <div class="single-item" v-for="(course, index) in candidate.courses" :key="index">
                <p class="single-item-title">{{ course.name }}</p>
                <p class="single-item-subtitle">{{ course.year }}</p>
                <p>{{ course.description }}</p>
            </div>                    
        </div>

        <div class="item-content-section" v-if="candidate.internships?.length">
            <h2>{{ $t("Internships") }}</h2>
                <div class="single-item" v-for="(internship, index) in candidate.internships" :key="index">
                <div class="item-details-table">
                    <div class="detail">
                        <p class="detail-title">{{ $t('Job title') }}</p>
                        <p>{{ internship.job_title }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Employer') }}</p>
                        <p>{{ internship.employer }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Industry') }}</p>
                        <p>{{ internship.industry_name }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Date') }}</p>
                        <p v-if="internship.date_from || internship.date_to" v-html="(internship.date_from && internship.date_to ? `<span>${$t('Start date')}:<b>${internship.date_from}</b></span><span>${$t('End date')}:<b>${internship.date_to}</b></span>` : (internship.date_from ? `${$t('Start date')}:<b>${internship.date_from}</b>` : `${$t('End date')}:<b>${internship.date_to}</b>`))"></p>
                        <p v-else>-</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Country') }}</p>
                        <p>{{ internship.country_name }}</p>
                    </div>
                </div>
                <p>{{ internship.description }}</p>
            </div>                
        </div>

        <div class="item-content-section" v-if="candidate.certificates?.length">
            <h2>{{ $t("Certificates") }}</h2>
            <div class="single-item" v-for="(certificate, index) in candidate.certificates" :key="index">
                <p class="single-item-title">{{ certificate.name }}</p>
                <p class="single-item-subtitle text-gray" v-if="certificate.date_from && certificate.date_to">
                    {{ (certificate.date_from && certificate.date_to ? `${certificate.date_from} - ${certificate.date_to}` : certificate.date_from ? certificate.date_from : `${certificate.date_from}${certificate.date_to}`) }}
                </p>
                <p>{{ certificate.description }}</p>
            </div>                    
        </div>
    </div>
</template>

<script>
export default {
    props: ['candidate']
}
</script>