<template>
    <div class="item-tab">
        <div class="item-content-section" v-if="candidate.about?.description">
            <h2>{{ $t("About") }}</h2>
            <p>{{ candidate.about?.description }}</p>
        </div>
        <div class="item-content-section" v-if="candidate.educations?.length">
            <h2>{{ $t("Education") }}</h2>
            <div class="single-item" v-for="(education, index) in candidate.educations" :key="index">
                <p class="single-item-title">{{ education.institution }}</p>
                <p class="single-item-subtitle text-gray" v-if="education.year_from || education.year_to">
                    {{ (education.year_from && education.year_to ? `${education.year_from} - ${education.year_to}` : `${education.year_from}${education.year_to}`) }}
                </p>
                <p>{{ education.description }}</p>
            </div>                    
        </div>
        <div class="item-content-section" v-if="candidate.achievements?.length">
            <h2>{{ $t("Achievements") }}</h2>
            <div class="single-item badge-item" v-for="(achievement, index) in candidate.achievements" :key="index">
                <p>{{ achievement.name }}</p>
            </div>
        </div>
        <div class="item-content-section" v-if="candidate.hobbies?.skills">
            <h2>{{ $t("Skills") }}</h2>
            <div class="single-item badge-item" v-for="(skill, index) in candidate.skills" :key="index">
                <p>{{ skill.name }}</p>
            </div>
        </div>
        <div class="item-content-section" v-if="candidate.hobbies?.length">
            <h2>{{ $t("Hobbies") }}</h2>
            <div class="single-item badge-item" v-for="(hobby, index) in candidate.hobbies" :key="index">
                <p>{{ hobby.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['candidate']
}
</script>